@charset "UTF-8";

.o-scroll-top {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  transform: translate(0, -30px);
  &:hover {
    cursor: pointer;
  }
}