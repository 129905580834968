@charset "UTF-8";

/* ---------------------------
  common
--------------------------- */
.o-page-header {
  min-height: 280px;
  .l-wrapper {
    min-height: 280px;
    position: relative;
    .__inner-depth-1 {
      padding: 0 15px;
      position: relative;
      min-height: 280px;
      .__title-text {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0 15px 20px 15px;
      }
    }
  }
}
.o-anchor-navi {
  .__inner-depth-1 {
    padding: 0 15px;
    .__list {
      .__item {
        display: inline-block;
        &:not(:last-child) {
          margin: 0 5px 0 0;
        }
        a {
          display: block;
          background: $color-gray;
          font-size: 18px;
          padding: 0 10px;
          border-radius: 0 0 5px 5px;
          min-width: 180px;
          text-decoration: none;
          transition: $default-transition;
          &:hover {
            color: $color-white;
            background: $color-orange;
          }
        }
      }
      &.__narrow {
        .__item {
          &:not(:last-child) {
            margin: 0 3px 0 0;
          }
          a {
            min-width: 140px;
          }
        }
      }
    }
  }
}
@include media-query(md) {
  .o-anchor-navi {
    display: none;
  }
}

/* ---------------------------
  02_philosophy
--------------------------- */
.o-page-philosophy {
  .o-page-header {
    background: url(../img/site/00_common/bg_pageheader_01.jpg) no-repeat center center/cover;
  }
  .o-section-01 {
    padding: 60px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 30px 0;
        }
        .__thumbnail {
          position: relative;
          margin: 0 0 15px 0;
          .__image {
            width: 100%;
          }
          .__caption {
            background: rgba(0,0,0,0.5);
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: right;
            padding: 10px 30px;
            .__position {
              color: $color-white;
              font-size: 1.4rem;
              display: inline-block;
              line-height: 34px;
            }
            .__name {
              padding: 0 0 0 10px;
            }
          }
          @include media-query(sm) {
            .__caption {
              position: static;
              background: $color-black;
              .__position {
                line-height: 1;
                font-size: 1.2rem;
                margin: 0 0 10px 0;
              }
            }
          }
        }
        .__text {
          font-size: 1.4rem;
        }
      }
    }
  }
  .o-section-02 {
    padding: 0 0 60px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 30px 0;
        }
        .__inner-depth-2 {
          .__lead {
            margin: 0 0 10px 0;
          }
          &--1st {
            margin: 0 0 30px 0;
          }
          &--2nd {
            .__inner-depth-3 {
              padding: 0 25px;
              .__list {
                padding: 0 0 0 15px;
                margin: 30px 0;
                border-left: 15px solid $color-orange;
                counter-reset: number 0;
                .__item {
                  padding: 0 0 0 18px;
                  position: relative;
                  &::before {
                    counter-increment: number 1;
                    content: counter(number)".";
                    position: absolute;
                    left: 0;
                    top: 0;
                  }
                }
              }
              .__text {
                margin: 0 0 30px 0;
              }
              .__caption {
                text-align: right;
                span {
                  display: inline-block;
                }
              }
            }
            @include media-query(sm) {
              .__inner-depth-3 {
                padding: 0 10px;
              }
            }
          }
        }
      }
    }
  }
}

/* ---------------------------
  03_company
--------------------------- */
.o-page-company {
  .o-page-header {
    background: url(../img/site/00_common/bg_pageheader_02.jpg) no-repeat center center/cover;
  }
  .o-section-01 {
    padding: 60px 0 30px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 30px 0;
        }
        .__inner-depth-2 {
          padding: 0 15px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .__inner-depth-3 {
            margin: 0 0 30px 0;
            &--1st {
              flex-basis: calc(60% - 15px);
              max-width: calc(60% - 15px);
            }
            &--2nd {
              flex-basis: calc(40% - 15px);
              max-width: calc(40% - 15px);
              .__box {
                display: flex;
                border: 1px solid #aaa;
                margin: 30px 0 0 0;
                .__icon {
                  background: #E4F5B8;
                  padding: 10px;
                  width: 100px;
                }
                .__text {
                  font-size: 1.4rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: calc(100% - 100px);
                  span {
                    text-align: left;
                    padding: 0 5px;
                  }
                  .__link {
                    color: #00f;
                    text-decoration: underline;
                  }
                }
              }
            }
            .__table {
              font-size: 1.4rem;
              th {
                min-width: 160px;
              }
            }
            @include media-query(md) {
              &--1st,
              &--2nd {
                flex-basis: 100%;
                max-width: 100%;
              }
              &--2nd {
                text-align: center;
              }
              .__table {
                th {
                  min-width: auto;
                }
              }
            }
          }
        }
        @include media-query(sm) {
          .__inner-depth-2 {
            padding: 0;
          }
        }
      }
    }
  }
  .o-section-02 {
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 30px 0;
        }
      }
      .__inner-depth-2 {
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .__inner-depth-3 {
          margin: 0 0 30px 0;
          &--1st {
            flex-basis: calc(60% - 15px);
            max-width: calc(60% - 15px);
          }
          &--2nd {
            flex-basis: calc(40% - 15px);
            max-width: calc(40% - 15px);
            .__image {
              &:first-child {
                margin: 0 0 30px 0;
              }
            }
          }
          .__list {
            font-size: 1.4rem;
            .__group {
              .__term {
                padding: 5px;
              }
              .__body {
                padding: 5px 5px 5px 20px;
              }
            }
          }
          @include media-query(md) {
            &--1st,
            &--2nd {
              flex-basis: 100%;
              max-width: 100%;
            }
            &--2nd {
              text-align: center;
            }
          }
        }
      }
      @include media-query(sm) {
        .__inner-depth-2 {
          padding: 0;
        }
      }
    }
  }
  .o-section-03 {
    padding: 0 0 30px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 30px 0;
        }
      }
      .__inner-depth-2 {
        &--1st {
          margin: 0 0 60px 0;
          padding: 0 15px;
        }
        &--2nd {
          margin: 0 0 30px 0;
          .__inner-depth-3 {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .__inner-depth-4 {
              flex-basis: calc(50% - 15px);
              max-width: calc(50% - 15px);
              &--1st {
                .__inner-depth-5 {
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: nowrap;
                  .__list {
                    border-left: 15px solid $color-orange;
                  }
                  .__thumbnail {
                  }
                }
              }
              &--2nd {
                .o-box-map {
                  position: relative;
                  padding-bottom: 56.5217%;
                  height: 0;
                  overflow: hidden;
                  iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
            @include media-query(md) {
              .__inner-depth-4 {
                flex-basis: 100%;
                max-width: 100%;
                &--1st {
                  margin: 0 0 15px 0;
                }
              }
            }
          }
        }
        &--3rd {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .__inner-depth-3 {
            flex-basis: calc(50% - 15px);
            max-width: calc(50% - 15px);
            margin: 0 0 30px 0;
            .__inner-depth-4 {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              .o-list-branch {
                flex-basis: calc(50% - 10px);
                max-width: calc(50% - 10px);
                border-left: 15px solid $color-orange;
                border-left: 15px solid $color-orange;
                .__term {
                  font-size: 1.8rem;
                }
                .__body {
                  font-size: 1.2rem;
                }
              }
              .o-box-map {
                flex-basis: calc(50% - 10px);
                max-width: calc(50% - 10px);
                position: relative;
                padding-bottom: 36%;
                height: 0;
                overflow: hidden;
                iframe {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                }
              }
              @include media-query(lg) {
                .o-list-branch,
                .o-box-map {
                  flex-basis: 100%;
                  max-width: 100%;
                }
                .o-list-branch {
                  margin: 0 0 15px 0;
                }
              }
            }
          }
          @include media-query(sm) {
            .__inner-depth-3 {
              flex-basis: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .o-section-04 {
    padding: 0 0 40px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 30px 0;
        }
        .__inner-depth-2 {
          padding: 0 15px;
          .__table {
            tbody {
              tr {
                th,td {
                  font-size: 1.4rem;
                }
                th {
                  width: 140px;
                }
                td {
                  width: calc(100% - 140px);
                }
                @include media-query(sm) {
                  th,td {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .o-section-05 {
    padding: 0 0 30px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 30px 0;
        }
        .__inner-depth-2 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 0 15px;
          .__inner-depth-3 {
            flex-basis: calc(50% - 15px);
            max-width: calc(50% - 15px);
            margin: 0 0 30px 0;
            &--1st {
              order: 1;
            }
            &--2nd {
              order: 2;
              .__lead {
                font-size: 1.6rem;
                font-weight: normal;
                margin: 0 0 15px 0;
                span {
                  display: inline-block;
                }
              }
              .__text {
                font-size: 1.2rem;
                margin: 0 0 15px 0;
              }
              .__list {
                padding: 0 10px;
                .__group {
                  border: 1px solid #aaa;
                  margin: 0 0 5px 0;
                  display: flex;
                  flex-wrap: nowrap;
                  .__term {
                    background: $color-gray;
                    flex-basis: 130px;
                    max-width: 130px;
                    font-size: 1.4rem;
                    padding: 0 5px;
                  }
                  .__body {
                    flex-basis: calc(100% - 130px);
                    max-width: calc(100% - 130px);
                    font-size: 1.4rem;
                    padding: 0 5px;
                    text-align: center;
                    a {
                      color: $color-blue;
                    }
                  }
                }
              }
            }
          }
          @include media-query(md) {
            .__inner-depth-3 {
              flex-basis: 100%;
              max-width: 100%;
              &--1st {
                order: 2;
                text-align: center;
              }
              &--2nd {
                order: 1;
              }
            }
          }
        }
        @include media-query(sm) {
          .__inner-depth-2 {
            padding: 0;
            .__inner-depth-3 {
              &--2nd {
                padding: 0;                
              }
              .__list {
               padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

/* ---------------------------
  04_business
--------------------------- */
.o-page-business {
  .o-page-header {
    background: url(../img/site/00_common/bg_pageheader_03.jpg) no-repeat center center/cover;
  }
  .o-section-01 {
    padding: 60px 0 20px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 40px 0;
        }
        .__inner-depth-2 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .__inner-depth-3 {
            flex-basis: calc(50% - 15px);
            max-width: calc(50% - 15px);
            margin: 0 0 40px 0;
            &--text {
              padding: 0 0 0 30px;
              .__lead {
                margin: 0 0 30px 0;
              }
              .__text {
                font-size: 1.4rem;
              }
            }
            &--content {
              .__text {
                font-size: 1.4rem;
              }
            }
          }
          &--2nd {
            .__inner-depth-3 {
              &--text {
                order: 2;
                padding: 0 30px 0 0;
              }
              &--thumb {
                order: 1;
              }
            }
          }
          &--4th {
            .__inner-depth-3 {
              .__lead {
                margin: 0;
              }
            }
          }
          @include media-query(md) {
            .__inner-depth-3 {
              flex-basis: 100%;
              max-width: 100%;
              &--text {
                padding: 0;
              }
              &--thumb {
                text-align: center;
              }
            }
            &--2nd {
              .__inner-depth-3 {
                &--text {
                  order: 1;
                }
                &--thumb {
                  order: 2;
                }
              }
            }
          }
        }
      }
    }
  }
  .o-section-02 {
    padding: 0 0 30px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 40px 0;
        }
        .__inner-depth-2 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .__inner-depth-3 {
            flex-basis: calc(50% - 15px);
            max-width: calc(50% - 15px);
            &--text {
              padding: 0 0 0 40px;
              .__lead {
                font-weight: normal;
                font-size: 2.4rem;
                margin: 0 0 5px 0;
                line-height: 1.4;
              } 
              .__sub {
                color: $color-blue;
                font-weight: normal;
                font-size: 1.8rem;
                margin: 0 0 30px 0;
              }
              .__text {
                font-size: 1.4rem;
                &:not(:last-of-type) {
                  margin: 0 0 30px 0;
                }
              }
              .__list {
                .__group {
                  margin: 0 0 30px 0;
                  font-size: 1.4rem;
                  .__term {
                    padding: 0 0 0 18px;
                    position: relative;
                    margin: 0 0 5px 0;
                    &::before {
                      content: "";
                      width: 14px;
                      height: 14px;
                      border-radius: 100%;
                      background: $color-orange;
                      position: absolute;
                      left: 0;
                      top: 5px;
                    }
                  }
                }
              }
            }
            &--thumb {
              .__image {
                &:not(:last-child) {
                  margin: 0 0 30px 0;
                }
              }
            }
          }
          @include media-query(md) {
            .__inner-depth-3 {
              flex-basis: 100%;
              max-width: 100%;
              margin: 0 0 30px 0;
              &--text {
                padding: 0;
              }
              &--thumb {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

/* ---------------------------
  05_recruit
--------------------------- */
.o-page-recruit {
  .o-page-header {
    background: url(../img/site/00_common/bg_pageheader_04_02.jpg) no-repeat center center/cover;
  }
  .o-section-01 {
    padding: 60px 0 10px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__inner-depth-2 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .__inner-depth-3 {
            flex-basis: calc(50% - 15px);
            max-width: calc(50% - 15px);
            margin: 0 0 30px 0;
            &--text {
              order: 2;
              font-size: 1.4rem;
            }
            &--thumb {
              order: 1;
            }
          }
          @include media-query(md) {
            .__inner-depth-3 {
              flex-basis: 100%;
              max-width: 100%;
              &--text {
                order: 1;
              }
              &--thumb {
                order: 2;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  .o-section-02,
  .o-section-03 {
    padding: 0 0 30px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 30px 0;
        }
        .__inner-depth-2 {
          padding: 0 15px;
          .__text {
            font-size: 1.2rem;
            margin: 0 0 30px 0;
          }
          .__list {
            .__item {
              display: inline-block;
              text-align: left;
              vertical-align: middle;
              &:not(:last-child) {
                margin: 0 15px 0 0;
                @include media-query(md) {
                  margin: 0 15px 30px 0;
                }
                
              }
              a {
                color: $color-blue;
              }
            }
          }
        }
      }
    }
  }
  .o-section-02,
  .o-section-03 {
    .l-wrapper {
      .__inner-depth-1 {
        .__inner-depth-2 {
          .__list {
            .__item {
              &:nth-child(2) {
                text-align: left;
              }
              .__element {
                font-size: 1.4rem;
                .__link,
                .__tel {
                  color: $color-blue;
                }
                .__tel {
                  font-weight: bold;
                  font-size: 1.8rem;
                  display: inline-block;
                }
              }
              .__caption {
                font-size: 1.2rem;
                text-indent: 0.5em;
                padding: 5px 0 0 0;
                &.__danger {
                  color: #ff0000;
                }
              }
            }
          }
        }
      }
    }
  }
  .o-section-02 {
    padding: 0 0 40px 0;
    .__lead {
      margin: 0 0 20px 0;
      font-size: 16px;
    }
    .__list {
      margin: 0 0 30px 0;
      // @include media-query(md) {
        
      // }
    }
  }
  .o-section-03 {
    padding: 0 0 50px 0;
    .__inner-depth-2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 10px;
      row-gap: 20px;
      flex-wrap: wrap;
      &.__inner-depth-2-01,
      &.__inner-depth-2-02 {
        .__inner-depth-3 {
          &.__inner-depth-3--1st {
            max-width: 340px;
            width: 100%;
          }
          .__text {
            margin: 20px 0 0 0;
            font-size: 1.4rem;
          }
        }
      }
      .__flow-list {
        .__item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: 30px;
          &:not(:first-child) {
            margin: 20px 0 0 0;
          }
          .__term {
            text-align: center;
            width: 70px;
            line-height: 1;
            background: #ff9900;
            color: #fff;
            font-size: 3.6rem;
            font-weight: bold;
            padding: 0 0 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &::after {
              content: "";
              width: 20px;
              height: 100%;
              position: absolute;
              left: 100%;
              top: 0;
              background: #ff9900;
              clip-path: polygon(100% 50%, 0 0, 0 100%);
            }
          }
          .__body {
            font-weight: bold;
          }
        }
      }
      .__caption-list {
        li {
          &:not(:first-child) {
            margin: 2px 0 0 0;
          }
          padding: 0 0 0 1em;
          position: relative;
          &::before {
            content: "◎";
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
    .l-wrapper .__inner-depth-1 .__inner-depth-2 .__box {
      display: flex;
      border: 1px solid #aaa;
      .__icon {
        background: #ffcc66;
        padding: 10px;
        width: 100px;
      }
      .__text {
        font-size: 1.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 100px);
        margin: 0;
        span {
          text-align: left;
          padding: 0 20px;
        }
        .__link {
          color: #00f;
          text-decoration: underline;
        }
      }
    }
  }
  .o-section-04 {
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 15px 0;
        }
        .__caption {
          font-size: 1.4rem;
          padding: 0 15px;
        }
      }
      .__inner-depth-2 {
        margin: 60px 0;
      }
    }
  }
}

/* ---------------------------
  06_policy
--------------------------- */
.o-page-policy {
  .o-page-header {
    background: url(../img/site/00_common/bg_pageheader_01.jpg) no-repeat center center/cover;
  }
  .o-section-01 {
    padding: 60px 0 0 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading,
        .__text {
          margin: 0 0 30px 0;
        }
        .__list {
          padding: 0 15px;
          .__group {
            margin: 0 0 30px 0;
            font-size: 1.4rem;
            .__term {
              position: relative;
              padding: 0 0 0 20px;
              line-height: 12px;
              margin: 0 0 15px 0;
              &::before {
                content: "";
                width: 10px;
                height: 10px;
                background: $color-orange;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }
          &.__list--horizontal {
            .__group {
              display: flex;
              .__term {
                flex-shrink: 0;
                flex-grow: 0;
                margin: 0;
                min-width: 3em;
              }
              .__body {
                flex-shrink: 1;
                flex-grow: 1;
                margin-top: -0.5em;
              }
            }
          }
        }.__button {
          display: block;
          text-align: center;
          margin: 0 0 60px 0;
        }
      }
    }
  }
}