@charset "UTF-8";

/* ---------------------------
  common
--------------------------- */

/* ---------------------------
  01_home
--------------------------- */
.o-front-page {
  .o-first-view {
    position: relative;
    min-height: 560px;
    .o-slide {
      .__slide-view {
        height: 560px;
      }
    }
    .l-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 50;
      width: 100%;
      height: 560px;
      display: flex;
      align-items: center;
      .__inner-depth-1 {
        padding: 0 15px;
        text-align: center;
        .__image {
          transform: translate(0, 30px);
        }
      }
    }
    .o-news-ticker {
      background: $color-black;
      .__inner-depth-1 {
        padding: 0 15px;
        .__list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          padding: 10px 0;
          .__item {
            &.__label {
              color: $color-white;
              font-weight: bold;
              flex-basis: 150px;
              max-width: 150px;
            }
            &.__news {
              flex-basis: calc(100% - 320px);
              max-width: calc(100% - 320px);
              a {
                color: $color-lightgreen;
                font-size: 1.4rem;
                font-weight: bold;
                line-height: 1.2;
                padding: 0 10px 0 0;
                display: inline-block;
              }
            }
            &.__button {
              flex-basis: 170px;
              max-width: 170px;
              a {
                font-size: 1.2rem;
                color: $color-orange;
                border: 1px solid $color-orange;
                text-decoration: none;
                display: block;
                text-align: center;
                transition: $default-transition;
                &:hover {
                  background: $color-orange;
                  color: $color-black;
                }
              }
            }
            @include media-query(md) {
              &.__news {
                flex-basis: calc(100% - 150px);
                max-width: calc(100% - 150px);
                a {
                  padding: 0;
                }
              }
              &.__button {
                flex-basis: 100%;
                max-width: 100%;
                padding: 10px 0 0 0;
                text-align: center;
                a {
                  margin: 0 auto;
                  max-width: 300px;
                }
              }
            }
            @include media-query(sm) {
              &.__label,
              &.__news {
                flex-basis: 100%;
                max-width: 100%;
              }
              &.__news {
                padding: 10px 0 0 0;
              }
            }
          }
        }
      }
    }
  }
  .o-section-01 {
    padding: 30px 0 0 0;
    background: $color-orange;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .o-home-navi {
          display: flex;
          flex-wrap: wrap;
          .__term {
            flex-basis: 100px;
            max-width: 100px;
            .__heading {
              font-size: 2.4rem;
              font-weight: bold;
              color: $color-white;
              line-height: 1;
            }
          }
          .__item {
            flex-basis: calc(100% - 200px);
            max-width: calc(100% - 200px);
            .__list-child {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              .__item-child {
                padding: 0 15px 30px 15px;
                flex-basis: 25%;
                max-width: 25%;
                text-align: center;
                position: relative;
                a {
                  display: block;
                }
                .o-drop-down-navi {
                  position: absolute;
                  top: 100%;
                  left: 0;
                  background: $color-brown;
                  width: 100%;
                  padding: 15px;
                  z-index: 10;
                  min-width: 200px;
                  display: none;
                  .__item-grandchild {
                    text-align: left;
                    padding: 0 0 0 20px;
                    position: relative;
                    line-height: 1;
                    &:not(:last-child) {
                      margin: 0 0 10px 0;
                    }
                    &::before {
                      content: "";
                      width: 16px;
                      height: 16px;
                      border-radius: 100%;
                      background: $color-white;
                      display: inline-block;
                      position: absolute;
                      left: 0;
                      top: 0;
                    }
                    a {
                      color: $color-white;
                      text-decoration: none;
                      font-weight: bold;
                    }
                  }
                }
              }
              @include media-query(md) {
                .__item-child {
                  flex-basis: 50%;
                  max-width: 50%;
                  .o-drop-down-navi {
                    display: none;
                  }
                }
              }
              @include media-query(sm) {
                .__item-child {
                  flex-basis: 100%;
                  max-width: 100%;
                }
              }
            }
          }
        }
        .o-home-navi-mobile {
          .__term {
            text-align: center;
            .o-hamburger-home {
              font-weight: bold;
              &::after {
                content: "▼";
                padding: 0 0 0 15px;
              }
              &.__active {
                &::after {
                  content: "▲";
                } 
              }
            }
          }
          .__body {
            .__list-child {
              .__item-child {
                border-top: 1px solid $color-brown;
                a {
                  padding: 10px 0;
                  display: block;
                  text-align: center;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .o-section-02 {
    padding: 30px 0 0 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 15px 0;
          font-size: 2.4rem;
          font-weight: bold;
          line-height: 1;
        }
        .__inner-depth-2 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .__inner-depth-3 {
            flex-basis: calc(50% - 15px);
            max-width: calc(50% - 15px);
            margin: 0 0 30px 0;
            &--5th {
              flex-basis: 100%;
              max-width: 100%;
              .__mobile {
                display: none;
              }
              @include media-query(sm) {
                .__desktop {
                  display: none;
                }
                .__mobile {
                  display: inline;
                }
              }
            }
          }
          @include media-query(sm) {
            .__inner-depth-3 {
              flex-basis: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}