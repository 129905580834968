@charset "UTF-8";

.o-list-archive-topics {
  padding: 0 30px;
  .__item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &:not(:last-child) {
      margin: 0 0 30px 0;
    }
    .__date {
      flex-basis: 160px;
      max-width: 160px;
      background: $color-lightblue;
      text-align: center;
    }
    .__title {
      flex-basis: calc(100% - 160px);
      max-width: calc(100% - 160px);
      padding: 0 0 0 15px;
      a {
        color: $color-blue;
      }
    }
  }
}
@include media-query(sm) {
  .o-list-archive-topics {
    padding: 0;
  }
}
.o-list-unordered {
  .__item {
    padding: 0 0 0 1em;
    position: relative;
    &::before {
      content: "・";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
.o-list-branch {
  padding: 15px;
  .__term {
    span {
      display: inline-block;
    }
  }
  .__term {
    font-size: 1.8rem;
  }
  .__body {
    font-size: 1.2rem;
  }
}
.o-list-columns {
  .__group {
    &:not(:last-child) {
      margin: 0 0 30px 0;
    }
    .__term {
      margin: 0 0 15px 0;
      &:hover {
        cursor: pointer;
      }
    }
    .__body {
      padding: 0 15px;
      display: none;
      .__inner-depth-3 {
        .__inner-depth-4 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .__inner-depth-5 {
            flex-basis: calc(50% - 15px);
            max-width: calc(50% - 15px);
            margin: 0 0 30px 0;
            &--text {
              .__lead {
                font-size: 2.2rem;
                font-weight: normal;
                color: $color-orange;
                margin: 0 0 15px 0;
                letter-spacing: -1px;
                line-height: 1.4;
              }
              .__text {
                font-size: 1.4rem;
                line-height: 1.5;
                letter-spacing: -0.3px;
                text-align: justify;
              }
            }
          }
          @include media-query(md) {
            .__inner-depth-5 {
              flex-basis: 100%;
              max-width: 100%;
            }
          }
        }
        &--1st {
          .__inner-depth-4 {
            .__inner-depth-5 {
              &--text {
                order: 2;
              }
              &--thumb {
                order: 1;
              }
              @include media-query(md) {
                &--text {
                  order: 1;
                }
                &--thumb {
                  order: 2;
                }
              }
            }
          }
        }
      }
      .__button {
        text-align: center;
        .__image {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  } 
}

.o-list-accordion-topics {
  .__item {

  }
  .__term {
    background: #ccc;
    margin: 0 30px;
    width: calc(100% - 60px);
    padding: 4px 30px;
    @include media-query(md) {
      margin: 0;
      width: 100%;
    }
    .__icon {
      color: #3366cc;
    }
    .__year {
      padding: 0 0 0 0.5em;
    }
  }
  .__body {
    display: none;
  }
}