@charset "UTF-8";

/* ---------------------------
  html body
--------------------------- */
html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
  body {
    width: inherit;
    height: inherit;
    min-height: 100vh;
    font-family: $default-font-family;
    font-size: $default-font-size;
    line-height: $default-line-height;
    color: $default-color;
  }
  @include media-query(sm) {
    body {
      font-size: 1.4rem;
    }
  }
  @include media-query(xs) {
    body {
      font-size: 1.4rem;
    }
  }
}

/* ---------------------------
  base layout
--------------------------- */
$header-min-height: auto;
$header-max-height: auto;
$footer-min-height: auto;
$footer-max-height: auto;
.l-base {
  width: inherit;
  height: auto !important;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template:
    "header" auto
    "main" 1fr
    "footer" auto
    / 100%;
}

/* ---------------------------
  header
--------------------------- */
.l-header {
  grid-area: header;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  .l-wrapper {
    background: rgba( 0,0,0,0.5);
    padding: 15px 0;
    border-bottom: 5px solid $color-green;
    .__inner-depth-1 {
      padding: 0 15px;
      .__inner-depth-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        .__inner-depth-3 {
          &--1st {
            padding: 0 15px 0 0;
          }
          &--2nd {
            flex-grow: 1;
            flex-shrink: 0;
            .__inner-depth-4 {
              .__inner-depth-5 {
                &--1st {
                  text-align: right;
                  .__group-name {
                    font-weight: normal;
                    font-size: 1.6rem;
                    color: $color-white;
                    margin: 0 0 5px 0;
                    a {
                      text-decoration: none;
                    }
                  }
                }
                &--2nd {
                  display: flex;
                  justify-content: flex-end;
                  .__language {
                    font-weight: normal;
                    font-size: 1.4rem;
                    color: $color-white;
                    line-height: 14px;
                    padding: 0 10px 0 0;
                  }
                  a {
                    line-height: 14px;
                  }
                }
                @include media-query(sm) {
                  &--1st {
                    .__group-name {
                      font-size: 1.4rem;
                    }
                  }
                  &--2nd {
                    flex-direction: column;
                    text-align: right;
                    .__language {
                      padding: 0 0 10px 0;
                      font-size: 1.2rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .l-global-navi {
    background: $color-black;
    &.__hidden {
      display: none;
    }
    .__inner-depth-1 {
      padding: 0 15px;
      .__list {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding: 10px 0;
        .__term {
          color: $color-white;
          font-size: 1.6rem;
          margin: 0 60px 0 0;
        }
        .__item {
          &:not(:last-of-type) {
            margin: 0 60px 0 0;
          }
          a {
            color: $color-orange;
            font-size: 1.8rem;
            text-decoration: none;
            position: relative;
            &::after {
              content: "";
              width: 100%;
              height: 2px;
              background: $color-orange;
              position: absolute;
              bottom: -2px;
              left: 0;
              transition: $default-transition;
              transform: scale(0, 1);
              transform-origin: center center;
            }
            &:hover {
              &::after {
                transform: scale(1, 1);
              }
            }
          }
        }
      }
      .__button-group {
        padding: 10px 0;
        display: none;
        .o-hamburger {
          font-size: 0;
          width: 100;
          width: 30px;
          height: 20px;
          margin: 0 auto;
          background: linear-gradient(to right, $color-white, $color-white) repeat-x center center/auto 2px;
          position: relative;
          display: block;
          &::before,
          &::after {
            content: "";
            width: 30px;
            height: 2px;
            background: $color-white;
            position: absolute;
            left: 0;
          }
          &::before {
            top: 0;
          }
          &::after {
            bottom: 0;
          }
          &.__active {
            background: transparent;
            &::before {
              transform: translate(0, 9px) rotate(45deg);
            }
            &::after {
              transform: translate(0, -9px) rotate(-45deg);
            }
          }
        }
      }
      .__link-back {
        font-size: 1.8rem;
        color: $color-white;
        text-decoration: none;
        padding: 10px 0;
        display: inline-block;
        &:hover {
          text-decoration: underline;
        }
      }
      @include media-query(md) {
        .__list {
          display: none;
        }
        .__button-group {
          display: block;
        }
      }
    }
    .__menu {
      background: $color-orange;
      display: none;
      .__item {
        border-bottom: 1px solid $color-brown;
        a {
          padding: 10px 15px;
          text-decoration: none;
          font-weight: bold;
          display: block;
          text-align: center;
        }
      }
    }
  }
}

/* ---------------------------
  footer
--------------------------- */
.l-footer {
  grid-area: footer;
  .l-wrapper {
    background: $color-gray;
    padding: 10px 0;
    .__inner-depth-1 {
      padding: 0 15px;
      .__list {
        text-align: center;
        .__item {
          display: inline-block;
          padding: 0 10px;
          line-height: 14px;
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            border-right: 1px solid $color-black;
          }
          a {
            font-size: 1.4rem;
            line-height: 14px;
            text-decoration: none;
          }
        }
      }
    }
  }
  .l-copyright {
    padding: 10px 0;
    .__inner-depth-1 {
      padding: 0 15px;
      .__text {
        display: block;
        text-align: center;
        font-size: 1.0rem;
        line-height: 1.2;
      }
    }
  }
}

/* ---------------------------
  main
--------------------------- */
.l-main {
  grid-area: main;
}

/* ---------------------------
  aside
--------------------------- */
.l-aside {
}

