@charset "UTF-8";

/* ---------------------------
  common
--------------------------- */

/* ---------------------------
  archive pages
--------------------------- */
.o-page-topics-archive {
  .o-page-header {
    background: url(../img/site/00_common/bg_pageheader_01.jpg) no-repeat center center/cover;
  }
  .o-section-01 {
    padding: 60px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__heading {
          margin: 0 0 30px 0;
        }
        .__list {
          margin: 0 0 60px 0;
        }
        .__button-box {
          text-align: center;
        }
      }
    }
  }
}
