@charset "UTF-8";

/* ---------------------------
  config
--------------------------- */
$container-gutter: $space-XXS;
$grid-gutter: $space-S;
$grid-sidebar-min-width: auto;
$grid-sidebar-max-width: 300px;

/* ---------------------------
  gird system (container)
--------------------------- */
.l-container {
  width: 100%;
  max-width: #{$screen-lg}px;
  margin: 0 auto;
}
.l-container-full {
  width: 100%;
  max-width: 100%;
  margin: 0;
}
@include media-query(xxl) {
  .l-container {
    max-width: #{$screen-lg}px;
  }
}
@include media-query(xl) {
  .l-container {
    max-width: #{$screen-lg}px;
  }
}
@include media-query(lg) {
  .l-container {
    max-width: 100%;
  }
}
@include media-query(md) {
  .l-container {
    max-width: 100%;
  }
}
@include media-query(sm) {
  .l-container {
    max-width: 100%;
  }
}
@include media-query(xs) {
  .l-container {
    max-width: 100%;
  }
}
@include media-query(xxs) {
  .l-container {
    max-width: 100%;
  }
}

/* ---------------------------
  gird system (flexbox)
--------------------------- */
.l-flex-layout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 $container-gutter;
  // margin: 0 calc(-1 * #{$container-gutter});
  .l-flex-item {
    padding: 0 $container-gutter;
    flex-basis: auto;
    max-width: none;
    flex-grow: 1;
    flex-shrink: 1;
    &.__no-gutter {
      padding: 0;
    }
    &.__col-1 { flex-basis: 8.3333%; max-width: 8.3333%; }
    &.__col-2 { flex-basis: 16.6666%; max-width: 16.6666%; }
    &.__col-3, &.__quad { flex-basis: 25%; max-width: 25%; }
    &.__col-4 { flex-basis: 33.3333%; max-width: 33.3333%; }
    &.__col-5 { flex-basis: 41.6666%; max-width: 41.6666%; }
    &.__col-6, &.__half { flex-basis: 50%; max-width: 50%; }
    &.__col-7 { flex-basis: 58.3333%; max-width: 58.3333%; }
    &.__col-8 { flex-basis: 66.6666%; max-width: 66.6666%; }
    &.__col-9, &.__quad-of-3 { flex-basis: 75%; max-width: 75%; }
    &.__col-10 { flex-basis: 83.3333%; max-width: 83.3333%; }
    &.__col-11 { flex-basis: 91.6666%; max-width: 91.6666%; }
    &.__col-12 { flex-basis: 100%; max-width: 100%; }
    @include media-query(switch) {
      &.__col-1,
      &.__col-2,
      &.__col-3,&.__quad,
      &.__col-4,
      &.__col-5,
      &.__col-6,&.__half,
      &.__col-7,
      &.__col-8,
      &.__col-9,&.__quad-of-3,
      &.__col-10,
      &.__col-11 { flex-basis: 100%; max-width: 100%; }
    }
  }
}

/* ---------------------------
  gird system (grid layout)
--------------------------- */
.l-grid-layout {
  display: grid;
  padding: 0 calc(#{$container-gutter} * 2);
  &.__col-quad {
    grid-template: auto / repeat(4, 1fr);
    grid-gap: $grid-gutter;
  }
  &.__col-triple {
    grid-template: auto / repeat(3, 1fr);
    grid-gap: $grid-gutter;
  }
  &.__col-double {
    grid-template: auto / repeat(2, 1fr);
    grid-gap: $grid-gutter;
  }
  &.__col-single {
    grid-template: auto / 100%;
  }
  &.__col-sidebar-left {
    grid-template: auto / minmax($grid-sidebar-min-width, $grid-sidebar-max-width) 1fr;
    grid-gap: $grid-gutter;
  }
  &.__col-sidebar-right {
    grid-template: auto / 1fr minmax($grid-sidebar-min-width, $grid-sidebar-max-width);
    grid-gap: $grid-gutter;
  }
  @include media-query(switch) {
    &.__col-quad,
    &.__col-triple,
    &.__col-double,
    &.__col-sidebar-left,
    &.__col-sidebar-right {
      grid-template: auto / 100%;
      grid-gap: 0;
    }
  }
  .l-grid-item {
    margin: 0;
    padding: 0;
  }
}