@charset "UTF-8";

/* ---------------------------
  html elements
--------------------------- */
noscript {
  background: #ff0;
  font-weight: bold;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
}
p:not(:last-child) {
  margin: 0 0 $space-S 0;
}
a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
}
@include media-query(sm) {
  p {
    text-align: justify;
  }
}
img {
  max-width: 100%;
  height: auto;
}

/* ---------------------------
  other settings
--------------------------- */
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.screen-reader-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  left: -9999px;
  top: -9999px;
}
