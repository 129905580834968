@charset "UTF-8";

/* ---------------------------
  common
--------------------------- */
.o-detail-topics {
  margin: 0 0 60px 0;
  .__date {
    display: block;
    padding: 5px 15px;
    font-size: 1.2rem;
    background: $color-gray;
    margin: 0 0 15px 0;
  }
  .__title {
    padding: 0 0 0 10px;
    border-left: 15px solid $color-orange;
    font-weight: normal;
    font-size: 2.4rem;
    margin: 0 0 15px 0;
    line-height: 1.4;
  }
  .__body {
    padding: 0 15px;
    * {
      font-size: 1.4rem;
    }
    a {
      color: $color-blue;
    }
  }
}

/* ---------------------------
  single pages
--------------------------- */
.o-page-topics-single {
  .o-page-header {
    background: url(../img/site/00_common/bg_pageheader_01.jpg) no-repeat center center/cover;
  }
  .o-section-01 {
    padding: 60px 0;
    .l-wrapper {
      .__inner-depth-1 {
        padding: 0 15px;
        .__button-box {
          text-align: center;
        }
      }
    }
  }
}
