@charset "UTF-8";

.o-on-marker {
  padding: 0 0 0 18px;
  position: relative;
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    background: $color-yellow;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 4px;
  }
}