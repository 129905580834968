@charset "UTF-8";

.o-table-default {
  width: 100%;
  & > thead {
  }
  & > tbody {
    & > tr {
      & > th {
        vertical-align: top;
        font-weight: normal;
        text-align: left;
        padding: 5px;
      }
      & > td {
        padding: 5px;
      }
      @include media-query(sm) {
        & > th,td {
          display: block;
          width: 100%;
        }
        & > th {
          padding: 5px;
        }
        & > td {
          padding: 5px 5px 5px 20px;
        }
      }
    }
  }
  & > tfoot {
  }
}
.o-table-inner {
  width: auto;
  thead {
    tr {
      th,td {
        padding: 0;
      }
    }
  }
  tbody {
    tr {
      th {
        vertical-align: top;
        font-weight: normal;
        text-align: left;
      }
    }
  }
  tfoot {
    tr {
      th,td {
        padding: 0;
      }
    }
  }
}
