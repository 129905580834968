@charset "UTF-8";

.o-heading-level-1 {
  font-size: 2.4rem;
  font-weight: normal;
  padding: 0 0 0 5px;
  border-bottom: 5px solid $color-orange;
}
.o-heading-level-2 {
  font-size: 1.8rem;
  font-weight: normal;
  padding: 0 0 0 28px;
  position: relative;
  &::before {
    content: "";
    width: 16px;
    height: 16px;
    background: $color-orange;
    border-radius: 100%;
    position: absolute;
    left: 5px;
    top: 8px;
  }
}

